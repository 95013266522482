/*
/!\ PLEASE DO NOT USE THE DEFAULT EXPORT OF THIS FILE TO GET COMPANY TYPES
    INSTEAD USE HELPER getCompanyTypes() from @/helper/company.js
*/

import { CLIENT, EXPERT } from '@/constants/userKind';

export const author = {
  label: 'fields.companyKinds.author',
  value: 'author',
  id: 'author',
  other: false,
  selectable: true,
  solo: false,
};

export const independent = {
  label: 'fields.companyKinds.independent',
  value: 'independent',
  id: 'independent',
  other: false,
  selectable: true,
  solo: false,
};

export const company = {
  label: 'fields.companyKinds.company',
  value: 'company',
  id: 'company',
  other: false,
  selectable: true,
  solo: false,
};

export const association = {
  label: 'fields.companyKinds.association',
  value: 'association',
  id: 'association',
  other: false,
  selectable: true,
  solo: false,
};

export const community = {
  label: 'fields.companyKinds.community',
  value: 'community',
  id: 'community',
  other: false,
  selectable: true,
  solo: false,
};

export const other = {
  label: 'fields.companyKinds.other',
  value: 'other',
  id: 'other',
  other: false,
  selectable: true,
  solo: false,
};

export const independentExpert = {
  label: 'fields.companyKinds.independent_expert',
  value: 'independent_expert',
  id: 'independent_expert',
  other: false,
  selectable: true,
  solo: true,
};

export const unknown = {
  label: 'fields.companyKinds.unknown',
  value: 'unknown',
  id: 'unknown',
  selectable: false,
  solo: true,
};

const companyKinds = {
  [EXPERT]: {
    independentExpert,
    company,
    unknown,
  },
  [CLIENT]: {
    author,
    independent,
    company,
    association,
    community,
    other,
    unknown,
  },
};

export default companyKinds;
