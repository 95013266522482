import React from 'react';
import pp from 'prop-types';
import Signup from '../Signup';
import Login from '../Login';

function OfflineDrawerScreen({ t }) {
  return (
    <>
      <Signup className="m-b-m" t={t} />
      <Login className="m-b-m tc" t={t} />
    </>
  );
}

OfflineDrawerScreen.propTypes = {
  t: pp.func.isRequired,
};

export default OfflineDrawerScreen;
