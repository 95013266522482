import React from 'react';
import { func, objectOf, any, string } from 'prop-types';
import uuid from 'uuid/v1';
import {
  RfHelper,
  FieldArray,
  RequiredField,
  RfChoices,
  RfLocation,
  RfLocations,
} from '@/containers/ReduxForm';
import LabelledRadio from '@/components/Choice/LabelledRadio';
import FormSection from '@/components/FormSection';
import modalitiesValues from '@/constants/fields/modalities';
import { FULLTIME_CONTRACT, MISSIONS } from '@/constants/contractTypes';
import { REMOTE } from '@/constants/expertMobility';
import { isCompanySolo } from '@/selectors/company';

const generatedAutocomplete = uuid();

function ModalitiesSection({ t, leader, companyKind }) {
  return (
    <FormSection title={t('company.modal.modalities.mobility.title')}>
      <RequiredField
        id="location_place"
        name="location_place"
        component={RfLocation}
        autoComplete={generatedAutocomplete}
        searchOptions={{ types: ['(cities)'] }}
        inputProps={{
          label: t(
            `postsignup.modalities.location.${
              isCompanySolo(companyKind) ? 'solo' : 'collective'
            }.label`
          ),
          placeholder: t('locations.input.placeholder'),
        }}
        className="tablet--one-whole one-half"
      />
      <RfHelper if="contract_types">
        {({ contract_types }) => {
          if (leader.kind === 'client') return null;
          if (
            !contract_types.includes(MISSIONS) &&
            !contract_types.includes(FULLTIME_CONTRACT)
          ) {
            return null;
          }

          return (
            <>
              <RequiredField
                inline
                type="radioGroup"
                name="modalities"
                component={RfChoices}
                checkbox={LabelledRadio}
                className="m-t-l m-b-l"
                label={t('company.modal.modalities.mobility.label')}
                cbProps={modalitiesValues.map((p) => ({
                  ...p,
                  label: t(p.label),
                }))}
                withSeparators={false}
              />
              <RfHelper if="modalities">
                {({ modalities }) =>
                  modalities !== REMOTE && (
                    <FieldArray
                      inline
                      component={RfLocations}
                      name="on_site_locations"
                      className="m-b-l"
                      label={t('company.modal.modalities.locations.label')}
                      addLabel={t('company.modal.modalities.locations.add')}
                      inputProps={{
                        label: t(
                          'company.modal.modalities.locations.input.label'
                        ),
                        placeholder: t('locations.input.placeholder'),
                      }}
                      useSpacer={false}
                      required
                    />
                  )
                }
              </RfHelper>
            </>
          );
        }}
      </RfHelper>
    </FormSection>
  );
}

ModalitiesSection.propTypes = {
  t: func.isRequired,
  leader: objectOf(any).isRequired,
  companyKind: string,
};

ModalitiesSection.defaultProps = {
  companyKind: null,
};

export default ModalitiesSection;
