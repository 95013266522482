import { MISSIONS } from '@/constants/contractTypes';
import { FORFEIT } from '@/constants/quotationKind';
import config from '@/_config';

export default {
  goals: [],
  work_days: [5],
  salary: [config.company.salary.min, config.company.salary.max],
  daily_rate: [config.company.dailyRate.min, config.company.dailyRate.max],
  contract_types: MISSIONS,
  billings: [FORFEIT],
};
