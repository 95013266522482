import {
  Comic,
  Document,
  Teaching,
  Youth,
  Memory,
  Detective,
  Thriller,
  Novel,
  ScienceFiction,
  Tale,
  Art,
  Eroticism,
  Humor,
  Sport,
  Religion,
  Science,
  SocialSciences,
  PersonalDevelopment,
  OtherWithoutPicto,
} from './sectors_default';

export default [
  Comic,
  Document,
  Teaching,
  Youth,
  Memory,
  Detective,
  Thriller,
  Novel,
  ScienceFiction,
  Tale,
  Art,
  Eroticism,
  Humor,
  Sport,
  Religion,
  Science,
  SocialSciences,
  PersonalDevelopment,
  OtherWithoutPicto,
];
